import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Container, Typography, List, Divider, Paper, ListItem } from '@mui/material';
import { NavbarPage } from '../Navbar/Navbar';
import { Footer } from '../Footer';

const TopMargin = styled.div`
  margin-top: 100px;
  text-align: justify;
`;

const StyledContainer = styled(Container)`
  background-color: #f0f8ff; /* Light blue background */
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px auto;
  text-align: justify;
`;

const ContentPaper = styled(Paper)`
  && {
    padding: 20px;
    margin-bottom: 20px;
  }
`;

const Icon = styled.span`
  margin-right: 10px;
`;

const TermsAndConditions = () => (
  <>
    <Helmet>
      <title> Conditions | WatchNod </title>
    </Helmet>
    <NavbarPage />
    <TopMargin />
    <StyledContainer>
      <Container>
        <h1>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Typography variant="h3" color="primary">
              Terms and Conditions
            </Typography>
          </div>
        </h1>
        <Typography style={{ marginBottom: '30px', marginTop: '20px' }}>
          The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and
          all Agreements: "Client," "You," and "Your" refers to you, the person who logs on this website and complies
          with the Company's terms and conditions. "The Company," "Ourselves," "We," "Our," and "Us," refers to our
          Company. "Party," "Parties," or "Us," refers to both the Client and ourselves.
        </Typography>
        <Divider />
        <ContentPaper elevation={3}>
          <Typography variant="h6" color="primary">
            <Icon>📄</Icon> Description of Service
          </Typography>
          <Typography>
            Monitoring Solution offers both free and paid service. The Service enables you to monitor servers. You agree
            to obtain prior verifiable permission from the owners of such resources that are being monitored using the
            Service.
          </Typography>
        </ContentPaper>

        <ContentPaper elevation={3}>
          <Typography variant="h6" color="primary">
            <Icon>🍪</Icon> Cookies
          </Typography>
          <Typography>
            We employ the use of cookies. By accessing Monitoring Solution, you agree to use cookies in agreement with
            Monitoring Solution's Privacy Policy.
          </Typography>
        </ContentPaper>

        <ContentPaper elevation={3}>
          <Typography variant="h6" color="primary">
            <Icon>🔒</Icon> License
          </Typography>
          <Typography>
            Unless otherwise stated, Monitoring Solution and/or its licensors own the intellectual property rights for
            all material on Monitoring Solution. All intellectual property rights are reserved. You may access this from
            Watch-Nod for your own personal use subjected to restrictions set in these terms and conditions.
          </Typography>
        </ContentPaper>

        <ContentPaper elevation={3}>
          <Typography variant="h6" color="primary">
            <Icon>🚫</Icon> You must not:
          </Typography>
          <List>
            <ListItem>Republish material from Monitoring Solution</ListItem>
            <ListItem>Sell, rent, or sub-license material from Monitoring Solution</ListItem>
            <ListItem>Reproduce, duplicate, or copy material from Monitoring Solution</ListItem>
            <ListItem>Redistribute content from Monitoring Solution</ListItem>
          </List>
        </ContentPaper>

        <ContentPaper elevation={3}>
          <Typography variant="h6" color="primary">
            <Icon>💬</Icon> User-Generated Content
          </Typography>
          <Typography>
            Parts of this website offer an opportunity for users to post and exchange opinions and information in
            certain areas of the website. Monitoring Solution does not filter, edit, publish, or review Comments prior
            to their presence on the website. Comments do not reflect the views and opinions of Monitoring Solution, its
            agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and
            opinions. To the extent permitted by applicable laws, Monitoring Solution shall not be liable for the
            Comments or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or
            posting of and/or appearance of the Comments on this website.
          </Typography>
        </ContentPaper>

        <ContentPaper elevation={3}>
          <Typography variant="h6" color="primary">
            <Icon>⚙️</Icon> Monitoring Solution reserves the right to:
          </Typography>
          <List>
            <ListItem>Monitor all Comments</ListItem>
            <ListItem>
              Remove any Comments which can be considered inappropriate, offensive, or causes a breach of these Terms
              and Conditions
            </ListItem>
          </List>
        </ContentPaper>

        <ContentPaper elevation={3}>
          <Typography variant="h6" color="primary">
            <Icon>🔐</Icon> You warrant and represent that:
          </Typography>
          <List>
            <ListItem>
              You are entitled to post the Comments on our website and have all necessary licenses and consents to do
              so;
            </ListItem>
            <ListItem>
              The Comments do not invade any intellectual property right, including without limitation copyright,
              patent, or trademark of any third party;
            </ListItem>
            <ListItem>
              The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material
              which is an invasion of privacy;
            </ListItem>
            <ListItem>
              The Comments will not be used to solicit or promote business or custom or present commercial activities or
              unlawful activity.
            </ListItem>
          </List>
          <Typography>
            You hereby grant Monitoring Solution a non-exclusive license to use, reproduce, edit, and authorize others
            to use, reproduce, and edit any of your Comments in any and all forms, formats, or media.
          </Typography>
        </ContentPaper>
      </Container>
    </StyledContainer>
    <Footer />
  </>
);

export default TermsAndConditions;
