import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { Button, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import { EditModal, UserItem } from '../sections/@dashboard/profile';
import Iconify from '../components/iconify';

export default function ProfilePage() {
  const [users, setUsers] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserById?userId=${userId}`, config);
      const userData = response.data.data;
      setUsers([userData]);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [token, userId, setUsers]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
  };

  const handleSaveUser = async (updatedUser) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const url = `${process.env.REACT_APP_API_URL}/updateUser?userId=${userId}`;
      const response = await axios.post(url, updatedUser, config);

      if (response.data.success) {
        setUsers([updatedUser]);
        fetchUser();
      } else {
        console.error('Error updating user data:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    } finally {
      setIsLoading(false);
    }
    setEditModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>User | WatchNod</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <h1>
            <Typography variant="h3" gutterBottom>
              Profile
            </Typography>
          </h1>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleEditClick(users[0])}
          >
            Edit
          </Button>
        </Stack>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Grid container spacing={3}>
                {users.map((user) => (
                  <Grid item xs={12} key={user._id}>
                    <UserItem user={user} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
        {selectedUser && (
          <EditModal isOpen={editModalOpen} user={selectedUser} onClose={handleCloseModal} onSave={handleSaveUser} />
        )}
      </Container>
    </>
  );
}
