import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import btnArrowIcon from './btn-arrow-icon.svg';

function SecondaryButton({ text, to }) {
  SecondaryButton.propTypes = {
    text: PropTypes.string,
    to: PropTypes.string,
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(to);
  };

  return (
    <Button
      variant="contained"
      sx={{
        width: {
          xs: '100%',
          md: 'auto',
        },
        backgroundColor: '#F6F4FF',
        textTransform: 'none',
        p: 1.25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 0.5,
        borderRadius: '12px',
        fontFamily: 'inherit',
        fontSize: '16px',
        fontWeight: '400',
        color: '#5243C2',
        '&.MuiButtonBase-root:hover': {
          backgroundColor: '#F6F4FF',
        },
      }}
      onClick={handleClick}
    >
      {text}
      <img src={btnArrowIcon} alt="Navigate to the next section" title="Next" width={15} height={15} loading="lazy"/>
    </Button>
  );
}

export default SecondaryButton;
