import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Dialog } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  SubscriptionPage,
  SubscriptionFormPage,
  CustomizedPlanBox,
  PurchaseFormPage,
} from '../sections/@dashboard/subscription';
import PurchaseCustomizeFormPage from '../sections/@dashboard/subscription/PurchaseCustomizeFormPage';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;
export default function SubscriptionPricingPage() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isPurchaseFormOpen, setIsPurchaseFormOpen] = useState(false);
  const [isPurchaseCustomizeFormOpen, setIsPurchaseCustomizeFormOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate();

  const openForm = (planId) => {
    setSelectedPlan(planId);
    setIsFormOpen(true);
  };
  const closeForm = () => {
    setIsFormOpen(false);
    navigate('/dashboard/subscription');
  };

  const openPurchaseForm = (planId) => {
    setSelectedPlan(planId);
    setIsPurchaseFormOpen(true);
  };

  const closePurchaseForm = () => {
    setIsPurchaseFormOpen(false);
    navigate('/dashboard/subscription');
  };

  const openPurchaseCustomizeForm = (planId) => {
    setSelectedPlan(planId);
    setIsPurchaseCustomizeFormOpen(true);
  };

  const closePurchaseCustomizeForm = () => {
    setIsPurchaseCustomizeFormOpen(false);
    navigate('/dashboard/subscription');
  };

  return (
    <>
      <Helmet>
        <title>Subscription | WatchNod</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <h1>
            <Typography variant="h3" gutterBottom>
              Subscription
            </Typography>
          </h1>
        </Stack>
        <StyledCard>
          <SubscriptionPage openForm={openForm} openPurchaseForm={openPurchaseForm} />
        </StyledCard>
        <Dialog open={isFormOpen} onClose={closeForm}>
          <StyledCard>
            <SubscriptionFormPage closeForm={closeForm} selectedPlan={selectedPlan} />
          </StyledCard>
        </Dialog>
        <Dialog open={isPurchaseFormOpen} onClose={closePurchaseForm}>
          <StyledCard>
            <PurchaseFormPage closePurchaseForm={closePurchaseForm} selectedPlan={selectedPlan} />
          </StyledCard>
        </Dialog>

        <Dialog open={isPurchaseCustomizeFormOpen} onClose={closePurchaseCustomizeForm}>
          <StyledCard>
            <PurchaseCustomizeFormPage
              closePurchaseCustomizeForm={closePurchaseCustomizeForm}
              selectedPlan={selectedPlan}
            />
          </StyledCard>
        </Dialog>
        <br />
        <StyledCard>
          <CustomizedPlanBox openPurchaseCustomizeForm={openPurchaseCustomizeForm} />
        </StyledCard>
      </Container>
    </>
  );
}
