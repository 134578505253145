import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  Typography,
  styled,
  ListItemButton,
  ListItemText,
  Button,
  useTheme,
} from '@mui/material';
import { animateScroll as scroll } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import useMediaQuery from '@mui/material/useMediaQuery';
import DrawerItem from './DrawerItem';
import { Colors } from '../style/theme';
import useResponsive from '../../hooks/useResponsive';
import logo from '../../Logo/logo-watch-nod.svg';
import colorLogo from '../../Logo/watch-nod.svg';

const styles = {
  logo: {
    fontFamily: 'Arial, Poppins',
    fontSize: 'calc(12px + 2vw)',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: '#283593',
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    display: 'inline-block',
  },
};

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

const appBarStyles = (scrolling) => ({
  backgroundColor: scrolling ? '#ffffff' : 'transparent',
  width: '100%',
  height: scrolling ? '65px' : '92px',
});

const buttonStyle = {
  color: Colors.white,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  backgroundColor: Colors.blue,
  '&:hover': {
    backgroundColor: Colors.dark_blue,
    textDecoration: 'underline',
  },
  borderRadius: '10px',
  textDecoration: 'none',
};

function useNavbarLogic() {
  const [scrolling, setScrolling] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleItemClick = (index, onClick) => {
    setActiveItem(index);
    if (onClick) onClick();
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isScrolling = scrollTop > 50;
      setScrolling(isScrolling);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isTokenExpired = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return true;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding the token:', error);
      return true;
    }
  };

  const isLoggedIn =
    !!localStorage.getItem('token') &&
    !!localStorage.getItem('userId') &&
    !!localStorage.getItem('role') &&
    !isTokenExpired();

  return {
    scrolling,
    activeItem,
    navigate,
    theme,
    scrollToTop,
    scrollToSection,
    handleItemClick,
    setScrolling,
    setActiveItem,
    isLoggedIn,
  };
}

function Navbar() {
  const {
    scrolling,
    activeItem,
    navigate,
    theme,
    scrollToTop,
    scrollToSection,
    handleItemClick,
    setScrolling,
    setActiveItem,
    isLoggedIn,
  } = useNavbarLogic();

  const isDesktop = useResponsive('up', 'lg');

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const itemList = [
    {
      text: <strong>Home</strong>,
      onClick: () => scrollToTop(),
    },
    {
      text: <strong>Feature</strong>,
      onClick: () => scrollToSection('feature'),
    },
    {
      text: <strong>About</strong>,
      onClick: () => scrollToSection('about'),
    },
    {
      text: <strong>Testimonial</strong>,
      onClick: () => scrollToSection('testimonial'),
    },
    {
      text: <strong>Pricing</strong>,
      onClick: () => scrollToSection('pricing'),
    },
    {
      text: <strong>Document</strong>,
      onClick: () => navigate('/document'),
    },
    {
      button: <strong>Login</strong>,
      onClick: () => {
        if (isLoggedIn) {
          navigate('/dashboard');
        } else {
          navigate('/login');
        }
      },
    },
    {
      button: <strong>Signup</strong>,
      onClick: () => navigate('/signup'),
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isScrolling = scrollTop > 50;
      setScrolling(isScrolling);

      const sectionPositions = {
        feature: document.getElementById('feature')?.offsetTop,
        about: document.getElementById('about')?.offsetTop,
        testimonial: document.getElementById('testimonial')?.offsetTop,
        pricing: document.getElementById('pricing')?.offsetTop,
      };

      let activeSection = 'home';
      Object.keys(sectionPositions).forEach((section) => {
        if (scrollTop >= (sectionPositions[section] || 0) - 50) {
          activeSection = section;
        }
      });
      const activeIndex = itemList.findIndex(
        (item) => item.text && item.text.toString().toLowerCase().includes(activeSection)
      );

      setActiveItem(activeIndex);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, [itemList, setActiveItem]);

  return (
    <AppBar component="nav" position="fixed" sx={appBarStyles(scrolling, theme)} elevation={0}>
      <StyledToolbar>
        <Typography sx={styles.logo} onClick={() => scrollToTop()}>
          <img
            src={isLargeScreen && !scrolling ? logo : colorLogo}
            alt="WatchNod"
            title="WatchNod Logo"
            loading="eager"
            width={isLargeScreen ? 120 : 50}
            height={isLargeScreen ? 100 : 60}
          />
        </Typography>

        {!isDesktop ? (
          <Box sx={{ color: 'black' }}>
            <DrawerItem />
          </Box>
        ) : (
          <ListMenu>
            {itemList.map((item, index) => {
              const { text, onClick, button } = item;
              const isActive = activeItem === index;

              return (
                <ListItem key={index}>
                  {button ? (
                    <Button
                      onClick={() => handleItemClick(index, onClick)}
                      sx={{
                        ...buttonStyle,
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      {button}
                    </Button>
                  ) : (
                    <ListItemButton
                      component={item.component}
                      to={item.to}
                      disableRipple
                      sx={{
                        color: isActive ? Colors.black : Colors.dark_blue,
                        fontWeight: theme.typography.fontWeightBold,
                        border: 'none',
                        borderRadius: 'none',
                        textDecoration: 'none',
                        '&:hover': {
                          color: Colors.black,
                          backgroundColor: 'transparent',
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={() => handleItemClick(index, onClick)}
                    >
                      <ListItemText primary={text} />
                    </ListItemButton>
                  )}
                </ListItem>
              );
            })}
          </ListMenu>
        )}
      </StyledToolbar>
    </AppBar>
  );
}

export default Navbar;

function NavbarPage() {
  const {
    scrolling,
    activeItem,
    navigate,
    theme,
    scrollToTop,
    scrollToSection,
    handleItemClick,
    setScrolling,
    setActiveItem,
    isLoggedIn,
  } = useNavbarLogic();

  const isDesktop = useResponsive('up', 'lg');

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const itemList = [
    {
      text: <strong>Home</strong>,
      onClick: () => navigate('/'),
    },
    {
      text: <strong>Feature</strong>,
      onClick: () => {
        navigate('/');
        setTimeout(() => {
          scrollToSection('feature');
          setActiveItem(1);
        }, 100);
      },
    },
    {
      text: <strong>About</strong>,
      onClick: () => navigate('/about'),
    },
    {
      text: <strong>Testimonial</strong>,
      onClick: () => navigate('/testimonial'),
    },
    {
      text: <strong>Pricing</strong>,
      onClick: () => {
        navigate('/');
        setTimeout(() => {
          scrollToSection('pricing');
          setActiveItem(4);
        }, 100);
      },
    },
    {
      text: <strong>Document</strong>,
      onClick: () => navigate('/document'),
    },
    {
      button: <strong>Login</strong>,
      onClick: () => {
        if (isLoggedIn) {
          navigate('/dashboard');
        } else {
          navigate('/login');
        }
      },
    },
    {
      button: <strong>Signup</strong>,
      onClick: () => navigate('/signup'),
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isScrolling = scrollTop > 50;
      setScrolling(isScrolling);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setScrolling]);

  return (
    <AppBar component="nav" position="fixed" sx={appBarStyles(scrolling, theme)} elevation={0}>
      <StyledToolbar>
        <Typography
          sx={styles.logo}
          onClick={() => {
            scrollToTop();
            setActiveItem(null);
          }}
        >
          <img
            src={colorLogo}
            alt="WatchNod"
            title="WatchNod Logo"
            loading="eager"
            width={isLargeScreen ? 120 : 50}
            height={isLargeScreen ? 100 : 60}
          />
        </Typography>
        {!isDesktop ? (
          <Box sx={{ color: Colors.blue }}>
            <DrawerItem />
          </Box>
        ) : (
          <ListMenu>
            {itemList.map((item, index) => {
              const { text, onClick, button } = item;
              const isActive = activeItem === index;

              return (
                <ListItem key={index}>
                  {button ? (
                    <Button
                      onClick={() => handleItemClick(index, onClick)}
                      sx={{
                        ...buttonStyle,
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      {button}
                    </Button>
                  ) : (
                    <ListItemButton
                      disableRipple
                      sx={{
                        color: isActive ? Colors.black : Colors.dark_blue,
                        border: 'none',
                        textDecoration: 'none',
                        '&:hover': {
                          color: Colors.black,
                          backgroundColor: 'transparent',
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={() => handleItemClick(index, onClick)}
                    >
                      <ListItemText primary={text} />
                    </ListItemButton>
                  )}
                </ListItem>
              );
            })}
          </ListMenu>
        )}
      </StyledToolbar>
    </AppBar>
  );
}

export { Navbar, NavbarPage };
