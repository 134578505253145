import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import axios from 'axios';
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  CircularProgress,
  Modal,
  CardContent,
} from '@mui/material';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { Wallet } from '../sections/@dashboard/wallet';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

const TABLE_HEAD = [
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'fullName', label: 'Full Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'emailVerified', label: 'Email Verified', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'createdAt', label: 'Created On', alignRight: false },
  { id: 'updatedAt', label: 'Updated On', alignRight: false },
  { id: 'isActive', label: 'Is Active', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

const TABLE_HEAD_VIEW_PLAN = [
  { id: 'subscriptionTitle', label: 'Subscription Title', alignRight: false },
  { id: 'subscriptionDescription', label: 'Subscription Description', alignRight: false },
  { id: 'SubscriptionAmount', label: 'Subscription Amount', alignRight: false },
  { id: 'subscriptionDays', label: 'Subscription Days', alignRight: false },
  { id: 'TotalNumberOfIp', label: 'Total IP', alignRight: false },
  { id: 'expiredOn', label: 'Expired Date', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, propertyAccessor) {
  if (!Array.isArray(array)) {
    return [];
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(propertyAccessor(a[0]), propertyAccessor(b[0]));
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => propertyAccessor(_user).toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const selected = [];
  const selectedUserPlanView = [];
  const [orderBy, setOrderBy] = useState('userName');
  const [filterName, setFilterName] = useState('');
  const [userViewFilterName, setUserViewFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewSubscriptionModalOpen, setViewSubscriptionModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewPlanLoading, setIsViewPlanLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userViewPlan, setUserViewPlan] = useState([]);
  const [viewTransactionId, setViewTransactionId] = useState(null);
  const [openTransactionModel, setOpenTransactionModel] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAllUser`, config);
        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }
        const { data } = response;
        if (data.success) {
          setUsers(data.data);
        } else {
          console.error('Error fetching user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [token]);

  const fetchUserViewPlanData = async (_id) => {
    setIsViewPlanLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getSubscriptions`, {
        params: {
          userId: _id,
        },
        ...config,
      });
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
      const { data } = response;
      if (data.success) {
        if (!data.data) {
          setUserViewPlan([]);
          toast.error(data.message);
          handleCloseMenu();
        } else {
          setUserViewPlan(data.data);
        }
      } else {
        console.error('Error fetching user subscription plan data');
      }
    } catch (error) {
      console.error('Error fetching user subscription plan data:', error);
    } finally {
      setIsViewPlanLoading(false);
    }
  };

  const handleOpenMenu = (event, user) => {
    if (event.currentTarget) {
      setOpen(event.currentTarget);
      setSelectedUser(user);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterBySubscriptionName = (event) => {
    setPage(0);
    setUserViewFilterName(event.target.value);
  };

  const emptyRows = users ? (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0) : 0;
  const emptyRowsForUserViewPlan = userViewPlan
    ? page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - userViewPlan.length)
      : 0
    : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName, (user) => user.userName);
  const filteredUsersForUserViewPlan = applySortFilter(
    userViewPlan,
    getComparator(order, orderBy),
    userViewFilterName,
    (user) => user.subscriptionTitle
  );

  const isNotFound = !filteredUsers.length && !!filterName;
  const userViewNotFound = !filteredUsersForUserViewPlan.length && !!userViewFilterName;

  const handleEditClick = (_id) => {
    setSelectedUserId(_id);
    setEditModalOpen(true);
  };
  const handleDeleteClick = (_id) => {
    setSelectedUserId(_id);
    setDeleteDialogOpen(true);
  };

  const showRunningSubscription = (_id) => {
    fetchUserViewPlanData(_id);
    setViewSubscriptionModalOpen(true);
  };

  const showTransaction = (_id) => {
    setViewTransactionId(_id);
    setOpenTransactionModel(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setSelectedUserId(null);
    handleCloseMenu();
  };

  const handleCloseViewPlanModal = () => {
    setViewSubscriptionModalOpen(false);
    setSelectedUserId(null);
    handleCloseMenu();
  };

  const handleCloseViewTransactionModal = () => {
    setOpenTransactionModel(false);
    setViewTransactionId(null);
    handleCloseMenu();
  };

  const handleCloseDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedUserId(null);
    handleCloseMenu();
  };

  const scrollableTableStyle = {
    maxHeight: '400px',
    overflowY: 'auto',
  };

  const handleSaveUser = async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const data = {
        userId: selectedUserId,
        isActive: selectedUser?.isActive ? 'false' : 'true',
      };

      const url = `${process.env.REACT_APP_API_URL}/updateIsActiveThroughAdmin`;

      const response = await axios.post(url, data, config);

      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 2000 });
        try {
          const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/getAllUser`, config);
          if (userResponse.status === 200 && userResponse.data.success) {
            setUsers(userResponse.data.data);
            // toast.success(userResponse.data.message, { autoClose: 2000 });
          } else {
            console.error('Error fetching user data: ', userResponse.data.message);
            toast.error('Error fetching user data: ', userResponse.data.message, { autoClose: 2000 });
          }
        } catch (userError) {
          console.error('Error fetching user data:', userError);
          toast.error('Error fetching user data: ', userError.message, { autoClose: 2000 });
        }
        setEditModalOpen(false);
        setOpen(null);
        setSelectedUserId(null);
      } else {
        console.error('Error updating user data: ', response.data.message);
        toast.error('Error updating user data: ', response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.error('Error in handleSaveUser:', error);
      toast.error('Error updating user data: ', error.message, { autoClose: 2000 });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    setIsDeleteLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/deleteUser`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.success) {
        const updatedUsers = users.filter((user) => user._id !== userId);
        setUsers(updatedUsers);
        setDeleteDialogOpen(false);
        toast.success(response.data.message, { autoClose: 2000 });
      } else {
        console.error('Error deleting user:', response.data.message);
        toast.error('Error deleting user: ', response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Error deleting user: ', error.message, { autoClose: 2000 });
    } finally {
      setDeleteDialogOpen(false);
      setIsDeleteLoading(false);
      handleCloseMenu();
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0rem' }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Helmet>
            <title>User | WatchNod</title>
          </Helmet>

          <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <h1>
                <Typography variant="h3" gutterBottom>
                  User Dashboard
                </Typography>
              </h1>
              {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
            </Stack>

            <Card>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                searchName="Search User Name"
              />

              <Scrollbar style={scrollableTableStyle}>
                <TableContainer
                  sx={{
                    minWidth: 800,
                  }}
                >
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        const {
                          fullName,
                          userName,
                          email,
                          phoneNumber,
                          emailVerified,
                          role,
                          createdAt,
                          updatedAt,
                          isActive,
                        } = row;

                        return (
                          <TableRow hover key={index} tabIndex={-1} role="checkbox">
                            <TableCell padding="normal">{page * rowsPerPage + index + 1}</TableCell>
                            <TableCell align="left">{userName}</TableCell>
                            <TableCell align="left">{fullName}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{emailVerified ? 'Yes' : 'No'}</TableCell>
                            <TableCell align="left">{phoneNumber}</TableCell>
                            <TableCell align="left">{role}</TableCell>
                            <TableCell align="left">{new Date(createdAt).toLocaleString()}</TableCell>
                            <TableCell align="left">{new Date(updatedAt).toLocaleString()}</TableCell>
                            <TableCell align="left" style={{ color: isActive ? 'green' : 'red' }}>
                              {isActive ? 'Active' : 'InActive'}
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow key="empty-row" style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow key="is-not-found">
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>
                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                key="tablePagination"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>

          <Popover
            key="popover"
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            {/* Block User */}
            <MenuItem
              key="block"
              sx={{ color: selectedUser?.isActive ? 'error.main' : 'primary.main' }}
              onClick={() => handleEditClick(selectedUser?._id, selectedUser?.isActive)}
            >
              <Iconify icon={selectedUser?.isActive ? 'eva:lock-outline' : 'eva:unlock-outline'} sx={{ mr: 2 }} />
              <span style={{ fontWeight: 'bold' }}>{selectedUser?.isActive ? 'Block' : 'Unblock'}</span>
            </MenuItem>

            {/* Show Running Subscription */}
            <MenuItem
              key="show"
              sx={{ color: 'primary.main' }}
              onClick={() => showRunningSubscription(selectedUser?._id)}
            >
              <VisibilityIcon sx={{ mr: 1.5 }} />
              <span style={{ fontWeight: 'bold' }}>View Plan</span>
            </MenuItem>

            {/* Show User Transaction History */}
            <MenuItem key="show" sx={{ color: 'primary.main' }} onClick={() => showTransaction(selectedUser?._id)}>
              <VisibilityIcon sx={{ mr: 1.5 }} />
              <span style={{ fontWeight: 'bold' }}>Transaction</span>
            </MenuItem>

            {/* Delete User */}
            <MenuItem key="delete" sx={{ color: 'error.main' }} onClick={() => handleDeleteClick(selectedUser?._id)}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              <span style={{ fontWeight: 'bold' }}> Delete</span>
            </MenuItem>

            {/* Block and Unblock Model  */}
            {selectedUserId && (
              <Dialog open={editModalOpen} onClose={handleCloseModal}>
                <DialogTitle sx={{ textAlign: 'center' }}>Are You Sure!</DialogTitle>
                <DialogContent>
                  <Stack spacing={3} sx={{ p: 1, pr: 0 }}>
                    You want To {selectedUser?.isActive ? 'Block' : 'Unblock'} This User?
                  </Stack>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                      <Button onClick={(e) => handleSaveUser(e)} variant="contained" color="error">
                        Yes
                      </Button>
                      <Button onClick={handleCloseModal} variant="contained" color="primary">
                        Close
                      </Button>
                    </Box>
                  )}
                </DialogContent>
              </Dialog>
            )}

            {/* View Subscription Plan Model */}
            {userViewPlan.length > 0 && (
              <Modal open={viewSubscriptionModalOpen} onClose={handleCloseViewPlanModal}>
                <Box
                  sx={{
                    position: 'relative',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#fff',
                    '@media (min-width: 600px)': {
                      maxWidth: '60vw',
                    },
                    '@media (min-width: 960px)': {
                      maxWidth: '50vw',
                    },
                  }}
                >
                  <StyledCard>
                    <Box>
                      <DialogTitle variant="h4" sx={{ textAlign: 'center' }}>
                        Running Subscription Plan
                      </DialogTitle>
                      <StyledCard>
                        <Card>
                          <CardContent>
                            <Paper className="running-subscription">
                              <UserListToolbar
                                numSelected={selectedUserPlanView.length}
                                userViewFilterName={userViewFilterName}
                                onFilterName={handleFilterBySubscriptionName}
                                searchName="Search Subscription Title"
                              />
                              <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                  <Table>
                                    <UserListHead
                                      order={order}
                                      orderBy={orderBy}
                                      headLabel={TABLE_HEAD_VIEW_PLAN}
                                      rowCount={userViewPlan.length}
                                      numSelected={selectedUserPlanView.length}
                                      onRequestSort={handleRequestSort}
                                    />
                                    {isViewPlanLoading ? (
                                      <TableBody>
                                        <TableRow>
                                          <TableCell colSpan={7} align="center">
                                            <CircularProgress />
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    ) : (
                                      <>
                                        <TableBody>
                                          {filteredUsersForUserViewPlan
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                              const {
                                                subscriptionTitle,
                                                subscriptionDescription,
                                                SubscriptionAmount,
                                                subscriptionDays,
                                                TotalNumberOfIp,
                                                expiredOn,
                                              } = row;

                                              const parsedDate = new Date(expiredOn);

                                              const formattedDate = parsedDate.toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                second: 'numeric',
                                                timeZone: 'UTC',
                                              });

                                              return (
                                                <TableRow hover key={index} tabIndex={-1} role="checkbox">
                                                  <TableCell padding="normal">
                                                    {page * rowsPerPage + index + 1}
                                                  </TableCell>
                                                  <TableCell align="left">{subscriptionTitle}</TableCell>
                                                  <TableCell align="left">{subscriptionDescription}</TableCell>
                                                  <TableCell align="left">{SubscriptionAmount}</TableCell>
                                                  <TableCell align="left">{subscriptionDays}</TableCell>
                                                  <TableCell align="left">{TotalNumberOfIp}</TableCell>
                                                  <TableCell align="left">{formattedDate}</TableCell>
                                                </TableRow>
                                              );
                                            })}
                                          {emptyRowsForUserViewPlan > 0 && (
                                            <TableRow key="empty-row" style={{ height: 53 * emptyRowsForUserViewPlan }}>
                                              <TableCell colSpan={7} />
                                            </TableRow>
                                          )}
                                        </TableBody>
                                        {userViewNotFound && (
                                          <TableBody>
                                            <TableRow key="is-not-found">
                                              <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                                <Paper sx={{ textAlign: 'center' }}>
                                                  <Typography variant="h6" paragraph>
                                                    Not found
                                                  </Typography>
                                                  <Typography variant="body2">
                                                    No results found for &nbsp;
                                                    <strong>&quot;{userViewFilterName}&quot;</strong>.
                                                    <br /> Try checking for typos or using complete words.
                                                  </Typography>
                                                </Paper>
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        )}
                                      </>
                                    )}
                                  </Table>
                                </TableContainer>
                              </Scrollbar>
                              <TablePagination
                                key="tablePagination"
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={userViewPlan.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Paper>
                          </CardContent>
                        </Card>
                      </StyledCard>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                      <Button onClick={handleCloseViewPlanModal} variant="contained" color="primary">
                        Close
                      </Button>
                    </Box>
                  </StyledCard>
                </Box>
              </Modal>
            )}

            {/* View Transaction History Model */}
            {viewTransactionId && (
              <Modal open={openTransactionModel} onClose={handleCloseViewTransactionModal}>
                <Box
                  sx={{
                    position: 'relative',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#fff',
                    '@media (min-width: 600px)': {
                      maxWidth: '60vw',
                    },
                    '@media (min-width: 960px)': {
                      maxWidth: '50vw',
                    },
                  }}
                >
                  <StyledCard>
                    <DialogTitle variant="h4" sx={{ textAlign: 'center' }}>
                      Transaction History
                    </DialogTitle>
                    <Wallet admin userId={viewTransactionId} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                      <Button onClick={handleCloseViewTransactionModal} variant="contained" color="primary">
                        Close
                      </Button>
                    </Box>
                  </StyledCard>
                </Box>
              </Modal>
            )}

            {/* Delete Dialog Box  */}
            <Dialog open={deleteDialogOpen} onClose={handleCloseDelete}>
              <DialogTitle sx={{ textAlign: 'center' }}>Are You Sure!</DialogTitle>
              <DialogContent>
                <Stack spacing={3} sx={{ p: 1, pr: 0 }}>
                  You want to delete this user?
                </Stack>
                {isDeleteLoading ? (
                  <CircularProgress />
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={(e) => handleDeleteUser(selectedUser?._id, e)} variant="contained" color="error">
                      Delete
                    </Button>
                    <Button onClick={() => setDeleteDialogOpen(false)} variant="contained" color="primary">
                      Close
                    </Button>
                  </Box>
                )}
              </DialogContent>
            </Dialog>
          </Popover>
        </>
      )}
    </div>
  );
}
