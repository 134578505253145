import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  CircularProgress,
  Paper,
  Typography,
} from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import { UserListHead, UserListToolbar } from '../user';
import Scrollbar from '../../../components/scrollbar';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'createdAt', label: 'Created On', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];

function ContactSalesData(props) {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterName, setFilterName] = useState('');
  const selected = [];

  const navigate = useNavigate();

  const handleSelectUser = (id) => {
    props.openForm(id);
    navigate(`/dashboard/contact-sales/${id}`);
  };

  const handleRequestSort = (property) => {
    if (property === 'actions') {
      return;
    }
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    if (!Array.isArray(array)) {
      return [];
    }

    const stabilizedThis = array.map((el, index) => [el, index]);

    if (stabilizedThis.length > 0) {
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
    }

    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => (a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return b[orderBy] < a[orderBy] ? -1 : 1;
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAllContactSalesData`, config);

      if (response.data.success === true) {
        setData(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, orderBy, order]);

  const filteredData = stableSort(data, getComparator(order, orderBy)).filter((item) =>
    Object.values(item).join(' ').toLowerCase().includes(filterName.toLowerCase())
  );

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const slicedData = filteredData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  return (
    <>
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          searchName="Search Name"
        />

        <Scrollbar>
          <TableContainer
            component={Paper}
            sx={{
              minWidth: 800,
            }}
          >
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={0}
                onRequestSort={handleRequestSort}
              />
              {data && data.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={TABLE_HEAD.length}>
                      <Typography sx={{ marginTop: '15px', justifyContent: 'center', display: 'flex' }}>
                        No User Found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    slicedData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.phoneNumber}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{new Date(item.createdAt).toLocaleString()}</TableCell>
                        <TableCell>
                          {item.custmizedPlan === 'true' ? (
                            <Button variant="outlined" color="primary" disabled>
                              Customized
                            </Button>
                          ) : (
                            <Button variant="outlined" color="primary" onClick={() => handleSelectUser(item._id)}>
                              Customize Plan
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Card>
    </>
  );
}

ContactSalesData.propTypes = {
  openForm: PropTypes.func.isRequired,
};

export default ContactSalesData;
