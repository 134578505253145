import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import DocumentDetail from '../landingPage/page/Documentation/DocumentDetail';

export default function DocumentationPage() {
  return (
    <>
      <Helmet>
        <title>Documentation| WatchNod</title>
      </Helmet>

      <Container maxWidth="xl">
        <DocumentDetail />
      </Container>
    </>
  );
}
