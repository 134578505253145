import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Container, Typography, Divider, Paper, ListItem } from '@mui/material';
import { NavbarPage } from '../Navbar/Navbar';
import { Footer } from '../Footer';

const TopMargin = styled.div`
  margin-top: 100px;
`;

const StyledContainer = styled(Container)`
  background-color: #f0f8ff;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px auto;
`;

const StyledParagraph = styled(Typography)`
  && {
    margin-bottom: 10px;
  }
  text-align: justify;
`;

const Heading = styled(Typography)`
  margin-bottom: 20px;
  text-align: center;
`;

const SectionTitle = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Paragraph = styled(Typography)`
  && {
    margin-bottom: 15px;
  }
  text-align: justify;
`;

const ListContainer = styled(Paper)`
  padding: 10px 20px;
  margin-bottom: 15px;
`;

const bold = {
  fontWeight: 'bold',
};

const PrivacyPolicy = () => (
  <>
    <Helmet>
      <title>Privacy Policy - Understand How We Protect Your Data | WatchNod</title>
      <meta
        name="description"
        content="Read WatchNod's Privacy Policy to understand how we collect, use, and protect your information."
      />
      <meta name="keywords" content="Privacy Policy, WatchNod, data protection, user privacy, information security" />
      <link rel="canonical" href="https://www.watchnod.com/privacyPolicy" />
    </Helmet>

    <NavbarPage />
    <TopMargin />
    <StyledContainer>
      <h1>
        <Heading variant="h3">Watchnod - Privacy Policy</Heading>
      </h1>
      <Paragraph variant="body1">
        Welcome to Watch-Nod! This Privacy Policy describes how we collect, use, and disclose information when you use
        our monitoring-based tool. We are committed to protecting your privacy and ensuring the security of your
        information.
      </Paragraph>
      <Paragraph variant="body1">
        By using our product, you consent to the collection and use of your information as described in this Privacy
        Policy. Please read this policy carefully to understand our practices regarding your information and how we will
        treat it.
      </Paragraph>
      <SectionTitle variant="h6">Information We Collect</SectionTitle>
      <Paragraph variant="body1">
        When you use our monitoring tool, we may collect the following types of information:
      </Paragraph>
      <ListContainer elevation={3}>
        <ListItem>
          <StyledParagraph>
            <strong>1. Server Metrics Data : </strong>
            We collect server metrics data from the servers you add to our monitoring tool. This data may include
            information such as CPU usage, memory usage, disk usage, network traffic, and other system performance
            metrics.
          </StyledParagraph>
        </ListItem>
        <Divider />
        <ListItem>
          <StyledParagraph>
            <strong>2. User Account Information : </strong>
            When you sign up for an account, we collect information such as your name, email address, and company name.
          </StyledParagraph>
        </ListItem>
        <Divider />
        <ListItem>
          <StyledParagraph>
            <strong>3. Log Data : </strong>
            Like most websites and online services, we may collect certain information automatically and store it in log
            files. This information may include your IP address, browser type, operating system, referring/exiting
            pages, and date/time stamps.
          </StyledParagraph>
        </ListItem>
      </ListContainer>
      <SectionTitle variant="h6">How We Use Your Information</SectionTitle>
      <Paragraph variant="body1">We may use the information we collect for the following purposes:</Paragraph>
      <ListContainer elevation={3}>
        <ListItem>
          <StyledParagraph>
            <strong>1. Providing and Improving Our Services : </strong>
            We use the information to provide, maintain, and improve our monitoring tool, as well as to develop new
            features and functionality.
          </StyledParagraph>
        </ListItem>
        <ListItem>
          <StyledParagraph>
            <strong>2. Customer Support : </strong>
            We may use your information to respond to your inquiries, provide customer support, and address technical
            issues.
          </StyledParagraph>
        </ListItem>
        <ListItem>
          <StyledParagraph>
            <strong>3. Communications : </strong>
            We may send you service-related announcements, updates, and other important information about our product.
          </StyledParagraph>
        </ListItem>
        <ListItem>
          <StyledParagraph>
            <strong>4. Analytics : </strong>
            We may use aggregated and anonymized data for analytics purposes to understand how our product is being used
            and to improve our services.
          </StyledParagraph>
        </ListItem>
      </ListContainer>
      <SectionTitle variant="h6">Data Retention</SectionTitle>
      <Paragraph variant="body1">
        We retain server metrics data collected from clients for a period of 90 days. After this period, the data may be
        deleted or anonymized.
      </Paragraph>
      <SectionTitle variant="h6">Information Sharing and Disclosure</SectionTitle>
      <Paragraph variant="body1">
        We do not sell, trade, or otherwise transfer your information to third parties. However, we may share your
        information in the following circumstances:
      </Paragraph>
      <ListContainer elevation={2}>
        <ListItem>
          <StyledParagraph>
            <strong>1. Service Providers : </strong>
            We may engage third-party service providers to assist us in providing and maintaining our services. These
            service providers will have access to your information only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </StyledParagraph>
        </ListItem>
        <ListItem>
          <StyledParagraph>
            <strong>2. Legal Compliance : </strong>
            We may disclose your information if required to do so by law or in response to a valid legal request, such
            as a court order or subpoena.
          </StyledParagraph>
        </ListItem>
      </ListContainer>
      <SectionTitle variant="h6">Security</SectionTitle>
      <Paragraph variant="body1">
        We take the security of your information seriously and take reasonable measures to protect it from loss, misuse,
        and unauthorized access, disclosure, alteration, and destruction.
      </Paragraph>
      <SectionTitle variant="h6">Your Choices</SectionTitle>
      <Paragraph variant="body1">
        You may update, correct, or delete your account information at any time by logging into your account settings.
        You may also unsubscribe from receiving promotional emails from us by following the instructions provided in the
        email.
      </Paragraph>
      <SectionTitle variant="h6">Changes to This Privacy Policy</SectionTitle>
      <Paragraph variant="body1">
        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements.
        We will notify you of any material changes by posting the new Privacy Policy on this page.
      </Paragraph>
      <SectionTitle variant="h6">Contact Us</SectionTitle>
      <Paragraph variant="body1">
        If you have any questions or concerns about this Privacy Policy or our practices regarding your information,
        please contact us at <Link>info@watchnod.com</Link>.
      </Paragraph>
      <Typography variant="body1" style={bold}>
        <i>Last updated : Jan 25, 2024</i>
      </Typography>
      <Typography variant="body1" style={bold}>
        <i>Watch-Nod</i>
      </Typography>
    </StyledContainer>
    <Footer />
  </>
);

export default PrivacyPolicy;
