import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import Overview from './WatchNodDocumentation/Overview';
import PublicIpAndNatIp from './WatchNodDocumentation/PublicIpAndNetIp';
import NodeExporter from './WatchNodDocumentation/NodeExporter';
import Policy from './WatchNodDocumentation/Policy';
import ContactSale from './WatchNodDocumentation/ContactSale';
import FAQ from './WatchNodDocumentation/FAQ';
import Dashboard from './WatchNodDocumentation/Dashboard';
import TypesOfIp from './WatchNodDocumentation/TypesOfIp';
import PublicIp from './WatchNodDocumentation/publicIp';

const ContentContainer = styled.div`
  background-color: #fff;
  min-height: 100vh;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const DocumentDetail = () => {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <ContentContainer>
      <h1>
        <Typography variant="h3" gutterBottom>
          WatchNod Documentation
        </Typography>
      </h1>
      <Overview />
      <Dashboard />
      <ContactSale />
      <TypesOfIp />
      <PublicIp />
      <PublicIpAndNatIp />
      <NodeExporter />
      <Policy />
      <FAQ />
    </ContentContainer>
  );
};

export default DocumentDetail;
