import React from 'react';
import { Container, Divider } from '@mui/material';
import styled from 'styled-components';
import a1 from '../../Assets/comprehensing-monitoring.webp';
import a2 from '../../Assets/user-friendly-dashboard.webp';
import a3 from '../../Assets/customize-sol.jpg';
import a4 from '../../Assets/security-first.jpg';

const TeamItem = styled.div`
  background-color: #f0f8ff;
  text-align: justify;
  margin: 20px;
  padding: 50px 30px 50px 30px;
  border-radius: 8px;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #3956de;
    color: white;
  }

  h3 {
    margin: 20px 0 1px 0 !important;
    color: #454545;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.25s ease-in-out;
  }

  &:hover h3 {
    color: #ddd;
  }
`;

const SectionWhite = styled.section`
  background: #fff;
  padding: 0;
`;

const TeamInfo = styled.div`
  display: block;
  margin-bottom: 0;

  p {
    color: #acacac;
    font-style: italic;
  }

  &::after {
    background: linear-gradient(135deg, #6b02ff 0%, #985bef 100%);
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 50px;
    height: 3px;
  }
`;

const TeamImg = styled.img`
  max-width: 300px;
  padding: 6px 6px;
  background-color: #dedede;
  border-radius: 5%;
  display: block;
  margin: auto;
`;

const TeamRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const TeamInfoContainer = styled.div`
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function AboutUs() {
  const message = `The forefront of server monitoring and analytics. Founded on the principles of reliability, efficiency, and innovation, we've dedicated ourselves to providing businesses with the tools they need to ensure seamless server operations.`;
  const teammessage = `Behind every line of code, every innovative feature, and every satisfied customer is a dedicated team committed to excellence. Our engineers, data scientists, and support staff work tirelessly to ensure that ResourceDekho remains at the forefront of server monitoring technology. We're not just experts in our field; we're passionate individuals driven by a shared vision: to redefine server monitoring for the modern age.`;
  return (
    <>
      <SectionWhite>
        <Container>
          <div className="row">
            <TeamInfoContainer className="col-md-12 mx-auto">
              <h2 className="section-title">Who We Are</h2>
              <p className="section-subtitle">
                <span>'Welcome to Watch-Nod' –</span>
                {message}
              </p>
            </TeamInfoContainer>
            <TeamRow>
              <TeamItem>
                <TeamImg
                  src={a1}
                  className="team-img"
                  alt="Comprehensive Monitoring"
                  width="300px"
                  height="auto"
                  loading="lazy"
                  title="Monitoring"
                />
                <h3>Comprehensive Monitoring</h3>
                <TeamInfo>{/* <p>Head of SEO</p> */}</TeamInfo>
                <p>
                  Dive deep into your server's health metrics, from uptime to latency and beyond. Our sophisticated
                  algorithms continuously scan your infrastructure, providing real-time updates and alerts.
                </p>
              </TeamItem>

              <TeamItem>
                <TeamImg
                  src={a2}
                  className="team-img"
                  width="300px"
                  alt="User-Friendly Dashboard"
                  height="auto"
                  loading="lazy"
                  title="User-Friendly Dashboard"
                />
                <h3>User-Friendly Dashboard</h3>
                <TeamInfo>{/* <p>Marketing Manager</p> */}</TeamInfo>
                <p>
                  We believe in simplicity. Our dashboard is designed with the end-user in mind, offering a seamless
                  experience that lets you monitor multiple servers, visualize data, and generate reports effortlessly.
                </p>
              </TeamItem>

              <TeamItem>
                <TeamImg
                  src={a3}
                  className="team-img"
                  width="300px"
                  alt="Customizable Solutions"
                  height="auto"
                  loading="lazy"
                  title="Customizable Solutions"
                />
                <h3>Customizable Solutions</h3>
                <TeamInfo>{/* <p>SEO Specialist</p> */}</TeamInfo>
                <p>
                  Recognizing that no two businesses are alike, we offer customizable monitoring solutions tailored to
                  your unique needs. Whether you're an SMB or a large enterprise, our platform scales with you.
                </p>
              </TeamItem>

              <TeamItem>
                <TeamImg
                  src={a4}
                  className="team-img"
                  width="300px"
                  alt="Security First"
                  height="auto"
                  loading="lazy"
                  title="Security First"
                />
                <h3>Security First</h3>
                <TeamInfo>{/* <p>SEO Specialist</p> */}</TeamInfo>
                <p>
                  In an era where cyber threats are ever-evolving, we prioritize your server's security. With advanced
                  encryption protocols and robust monitoring, rest assured that your data is in safe hands.
                </p>
              </TeamItem>
            </TeamRow>
          </div>
        </Container>
      </SectionWhite>
      <Divider />
      <SectionWhite>
        <Container>
          <div className="row">
            <TeamRow>
              <TeamItem>
                <h2 className="section-title">OUR TEAM</h2>
                <p className="section-subtitle">{teammessage}</p>
              </TeamItem>
              <TeamItem>
                <h2 className="section-title">Join Us on This Journey</h2>
                <p className="section-subtitle">{teammessage}</p>
              </TeamItem>
            </TeamRow>
          </div>
        </Container>
      </SectionWhite>
    </>
  );
}

export default AboutUs;
