import React, { useState, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import FeatureBox from './FeatureBox';
import featureImage1 from './images/6.gif';
import featureImage2 from './images/3.gif';
import featureImage3 from './images/5.gif';
import featureImage4 from './images/7.gif';

export default function Feature() {
  const featureBoxes = [
    {
      image: featureImage1,
      title: 'Real-time Monitoring',
      description: 'Monitor your servers in real-time to ensure optimal performance.',
      animationClass: 'slide-left',
    },
    {
      image: featureImage2,
      title: 'Server Health Metrics',
      description: 'Track important server health metrics to identify issues proactively.',
      animationClass: 'slide-right',
    },
    {
      image: featureImage3,
      title: 'Alerts and Notifications',
      description: 'Receive alerts and notifications when critical events occur.',
      animationClass: 'slide-left',
    },
    {
      image: featureImage4,
      title: 'Historical Data and Reports',
      description: 'Access historical data and generate detailed reports for analysis.',
      animationClass: 'slide-right',
    },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleScroll = () => {
      const newPosition = window.scrollY;
      setScrollPosition(newPosition);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const boxStyles = (animationClass) => ({
    width: isMobile ? '100%' : '48%',
    padding: '20px',
    transition: 'transform 1.65s ease-in-out, opacity 1.65s ease-in-out',
    transform: isMobile
      ? 'translateX(0)'
      : `translateX(${scrollPosition > 200 ? 0 : animationClass === 'slide-left' ? -100 : 100}px)`,
    opacity: isMobile ? 1 : scrollPosition > 200 ? 1 : 0,
  });

  return (
    <Container id="feature" style={{ textAlign: 'center', marginTop: '2.5%' }}>
      <Box sx={{ marginBottom: 0 }}>
        <h1>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: '600',
              fontSize: '26px',
              lineHeight: '48px',
              color: '#161414',
              textAlign: {
                xs: 'center',
              },
            }}
          >
            Features
          </Typography>
        </h1>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {featureBoxes.map((feature, index) => (
          <Box
            key={index}
            style={{
              ...boxStyles(feature.animationClass),
            }}
          >
            <FeatureBox image={feature.image} title={feature.title} description={feature.description} />
          </Box>
        ))}
      </Box>
    </Container>
  );
}
