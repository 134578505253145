import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Logo from '../components/logo';
import ForgotPassword from '../sections/auth/ForgotPassword';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows?.card || '0px 4px 20px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ForgotPasswordPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title>Forgot Password | WatchNod</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <h1>
              <Typography sx={{ px: 5, mt: 10, mb: 5, fontSize: '2.3rem', fontWeight: 'bold', textAlign: 'center' }}>
                Hi, Welcome Back
              </Typography>
            </h1>
            <img
              src="/assets/illustrations/illustration_login.png"
              alt="Forgot Password"
              width="100%"
              height="auto"
              loading="lazy"
              title="Forgot Password"
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography sx={{ fontSize: '2rem', fontWeight: 'bold' }} gutterBottom>
              Forgot Password
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? <Link to="/signup">Get started</Link>
            </Typography>

            <ForgotPassword />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
