import React, { useState } from 'react';
import { Box } from '@mui/material';
import backgroundImage from './images/bg.jpg';

const Header = () => {
  const [imageError, setImageError] = useState(false);

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          flex: '1',
          alignSelf: 'center',
        },
        [theme.breakpoints.up('md')]: {
          flex: '2',
          alignSelf: 'flex-end',
        },
      })}
    >
      {imageError ? (
        <p
          style={{
            width: '100%',
            height: '100%',
            marginBottom: 20,
            objectFit: 'cover',
          }}
        >
          Loading WatchNod Landscape
        </p>
      ) : (
        <img
          src={backgroundImage}
          alt="Loading WatchNod Landscape"
          title="WatchNod Header Landscape" 
          width="100%"
          height="100%"
          loading="eager"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: 20,
            objectFit: 'cover',
          }}
          onError={() => setImageError(true)}
        />
      )}
    </Box>
  );
};

export default Header;
